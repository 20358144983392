.layout-stage.-person {
  margin: 0 auto;
  padding-top: 0;

  .sticky-backlink {
    padding: var(--spacing-xxl) 0;
  }
}

.person-presentation {
  max-width: 67.5rem;
  padding: var(--spacing-xxl);
  background: var(--color-neutral-surface-lighter);
  border: solid 1px var(--color-neutral-border-light);
  border-radius: var(--radius-rounded);
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-48);
}

.person-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--spacing-xl);

  &.-column {
    display: flex;
    gap: var(--spacing-xxl);
    padding: 0 var(--spacing-xxs);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.person-presentation__avatar {
  border: 1px solid var(--color-neutral-border-lighter);
  border-radius: 50%;
  display: inline-block;
  box-sizing: content-box;
  width: 100%;
  max-width: 8.9rem;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;

  .person-presentation__avatar-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &.-default-avatar {
    border: var(--color-global-state-hover-border-light);
    background: var(--color-global-state-hover-surface-light);

    .person-presentation__avatar-img {
      padding-top: var(--spacing-l);
      padding-bottom: var(--spacing-xxs);
      fill: var(--color-global-state-hover-text-shape-dark);
    }
  }
}

.person-presentation__name {
  @include font-sans-xl-strong;
  color: var(--color-neutral-text-shape-default);
}

.person-presentation__role {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
  @include font-sans-m-default;
  color: var(--color-neutral-text-shape-default);

  .main-role {
    @include font-sans-m-semi;
  }
}

@media (max-width: $breakpoint-l) {
  .layout-stage.-person {
    grid-template-columns: 0 minmax(0, 1fr) 0;
  }
}

@media (max-width: $breakpoint-m) {
  .person-presentation {
    padding: var(--spacing-xl);
    margin-bottom: var(--spacing-xxxxl);
  }

  .person-presentation__avatar {
    max-width: var(--spacing-88);
  }
}

@media (max-width: $breakpoint-s) {
  .person-presentation {
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-xxxl);
  }

  .person-box {
    max-width: 49.9rem;

    &.-column {
      gap: var(--spacing-xl)
    }
  }

  .person-presentation__avatar {
    max-width: var(--spacing-48);

    &.-default-avatar {
      .person-presentation__avatar-img {
        padding-top: var(--spacing-s);
        padding-bottom: var(--spacing-xxxs);
      }
    }
  }

  .person-presentation__name {
    @include font-sans-l-strong;
  }

  .person-presentation__role {
    @include font-sans-s-default;

    .main-role {
      @include font-sans-s-semi;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .person-presentation {
    margin-top: 0;
    margin-bottom: var(--spacing-xxl);
  }
}
