@use "mixins" as *;
/* apps/ui */
@import "../../apps/ui/static_src/ui/base/main.scss";
@import "../../apps/ui/static_src/ui/components/index.scss";
@import "../../apps/ui/static_src/ui/layout/index.scss";

/* apps/briefings */
@import "../../apps/briefings/static_src/briefings/base/briefing.scss";
@import "../../apps/briefings/static_src/briefings/brief_item/main.scss";
@import "../../apps/briefings/static_src/briefings/alerts.scss";

/* apps/editorials */
@import "../../apps/editorials/static_src/editorials/components/index.scss";
@import "../../apps/editorials/static_src/editorials/article/article.scss";
@import "../../apps/editorials/static_src/editorials/search/main.scss";
@import "../../apps/editorials/static_src/homepage/main.scss";

/* apps/feature_previews */
@import "../../apps/feature_preview/static_src/feature_preview/main.scss";

/* apps/keywords_alerts */
@import "../../apps/keywords_alerts/static_src/keywords_alerts/main.scss";

/* apps/monitoring */
@import "../../apps/monitoring/static_src/monitoring/alerts.scss";
@import "../../apps/monitoring/static_src/monitoring/components/index.scss";
@import "../../apps/monitoring/static_src/monitoring/emptystate.scss";

/* apps/paywalls */
@import "../../apps/paywall/static_src/paywall/base/main.scss";
@import "../../apps/paywall/static_src/paywall/lists/main.scss";
@import "../../apps/paywall/static_src/paywall/details/main.scss";

/* apps/subscriptions */
@import "../../apps/subscriptions/static_src/subscriptions/feature_dialog.scss";
@import "../../apps/subscriptions/static_src/subscriptions/tooltip.scss";
@import "../../apps/subscriptions/static_src/subscriptions/trial/main.scss";

/* apps/taxonomy */
@import "../../apps/taxonomy/static_src/taxonomy/edition.scss";
@import "../../apps/taxonomy/static_src/taxonomy/tag.scss";

/* apps/transcripts */
@import "../../apps/transcripts/static_src/transcripts/transcript.scss";

/* apps/users */
@import "../../apps/users/static_src/users/account/main.scss";
@import "../../apps/users/static_src/users/author/author.scss";
@import "../../apps/users/static_src/users/team.scss";
@import "../../apps/users/static_src/users/optout.scss";
@import "../../apps/users/static_src/users/auth/main.scss";

/* apps/bills */
@import "../../apps/bills/static_src/bills/bill_list/main.scss";

/* apps/people */
@import "../../apps/people/static_src/people/person/person.scss";
@import "../../apps/people/static_src/people/person/person_directory.scss";

/* apps/parliament */
@import "../../apps/parliament/static_src/parliament/parliamentarian/amendment.scss";
