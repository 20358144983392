@mixin amendment-font-color($default-color, $active-color) {
  color: $default-color;

  .amendment-link:hover &,
  .amendment-link:focus-visible &,
  .amendment-link:active & {
    color: $active-color;
  }
}

.amendment-list {
  max-width: 67.5rem;
}

.amendment-box {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  padding: var(--spacing-none);
  gap: var(--spacing-none);
  background: var(--color-neutral-surface-lighter);
  border-bottom: 1px solid var(--color-neutral-border-lighter);

  .amendment-link:hover &,
  .amendment-link:focus-visible & {
    border-bottom: var(--color-global-state-hover-border-dark);
    background: var(--color-global-state-hover-surface-default);
  }

  .amendment-link:active & {
    border-bottom: var(--color-global-state-hover-border-dark);
    background: var(--color-global-state-hover-surface-dark);
  }

  .amendment-link.-inactive {
    pointer-events: none;
  }
}

.amendment-box__column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: var(--spacing-xl);

  &.-left {
    min-width: 9.2rem;
  }

  &.-right {
    flex-grow: 1;
    gap: var(--spacing-xxs);
    min-width: 0;
  }
}

.amendment-box__date {
  @include font-condensed-s-strong;
  @include amendment-font-color(var(--color-neutral-text-shape-light), var(--color-neutral-text-shape-contrast-default));
}

.amendment-box__header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: var(--spacing-xxs);
}

.amendment-box__heading {
  display: flex;
  flex-wrap: wrap;
  @include amendment-font-color(var(--color-global-monito-text-shape-default), var(--color-global-monito-text-shape-light));
  @include font-sans-m-strong;

  .-default {
    @include amendment-font-color(var(--color-neutral-text-shape-default), var(--color-neutral-text-shape-contrast-default));
    @include font-sans-m-default;
  }
}

.amendment-box__fate-label {
  @include amendment-font-color(var(--color-neutral-text-shape-light), var(--color-neutral-text-shape-contrast-default));
  @include font-sans-m-semi;

  &.-adopted {
    @include amendment-font-color(var(--color-feedback-success-text-shape-default), var(--color-neutral-text-shape-contrast-default));
  }

  &.-rejected {
    @include amendment-font-color(var(--color-feedback-danger-text-shape-default), var(--color-neutral-text-shape-contrast-default));
  }
}

.amendment-box__bill-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include amendment-font-color(var(--color-neutral-text-shape-light), var(--color-neutral-text-shape-contrast-default));
  @include font-sans-s-semi;
}

.amendment-box__reason {
  @include amendment-font-color(var(--color-neutral-text-shape-light), var(--color-neutral-text-shape-contrast-default));
  @include font-sans-s-default;
}

@media (max-width: $breakpoint-s) {
  .amendment-box__column {
    padding: var(--spacing-s);

    &.-left {
      min-width: var(--spacing-56);
    }
  }

  .amendment-box__date {
    @include font-condensed-xs-strong;
  }

  .amendment-box__heading {
    flex-direction: column;
    @include font-sans-s-strong;
    .-default {
      @include font-sans-s-default;
    }
  }

  .amendment-box__fate-label {
    @include font-sans-s-semi;
  }

  .amendment-box__bill-name {
    @include font-sans-xs-semi;
  }

  .amendment-box__reason {
    @include font-sans-xs-default;
  }
}
