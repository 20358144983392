.chipfield {
  cursor: pointer;

  &:hover, &:focus-visible {
    background-color: var(--color-global-state-hover-border-default);
    color: var(--color-global-state-hover-text-shape-inverted);
  }

  &.-tag:has(> input[type=checkbox]:checked) {
    background-color: var(--color-global-state-hover-border-dark);
    color: var(--color-global-state-hover-text-shape-inverted);
  }
}
