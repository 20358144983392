@import "tippy.js/dist/tippy.css";
@import "tippy.js/animations/shift-toward.css";

// Forked from light theme
// Available at @import "tippy.js/themes/light.css";


.tippy-box[data-theme~=disco] {
  width: 30rem;
  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-global-background-lighter);
  box-shadow: 0px 0px var(--spacing-xs) 0px rgba(7, 0, 46, 0.15);
  color: inherit;
  overflow: hidden;
  transition-timing-function: ease-out;
}

.tippy-box[data-theme~=disco] .tippy-content {
  padding: 0;
}

.tippy-box[data-theme~=disco][data-placement^=top]>.tippy-arrow:before {
  border-top-color: #fff
}

.tippy-box[data-theme~=disco][data-placement^=bottom]>.tippy-arrow:before {
  border-bottom-color: #fff
}

.tippy-box[data-theme~=disco][data-placement^=left]>.tippy-arrow:before {
  border-left-color: #fff
}

.tippy-box[data-theme~=disco][data-placement^=right]>.tippy-arrow:before {
  border-right-color: #fff
}

.tippy-box[data-theme~=disco]>.tippy-backdrop {
  background-color: #fff
}

.tippy-box[data-theme~=disco]>.tippy-svg-arrow {
  fill: #fff
}
